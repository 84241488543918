<template>
	<div class="main-slider" v-if="slides">
		<swiper :options="swiperOption" ref="mySwiperMain">
			<swiperSlide
				class="main-slider__slide"
				v-for="slide in slides"
				:key="slide.id"
				:data-swiper-autoplay="(slide.delay * 1000) || 5000"
			>
				<div class="main-slider__container container">
					<div class="main-slider__content">
						<h3 class="main-slider__title">{{ slide.title }}</h3>
						<div
							class="main-slider__text editor"
							v-html="slide.description"
						></div>
						<template
							v-if="slide.url.match(/https:/) || slide.url.match(/http:/)"
						>
							<a :href="slide.url" target="_blank" class="button button--white"
								>Подробнее</a
							>
						</template>
						<template v-else>
							<router-link :to="slide.url" class="button button--white"
								>Подробнее</router-link
							>
						</template>
					</div>

					<div class="main-slider__image-wrapper">
						<img
							v-if="slide.background"
							:src="slide.background"
							alt="Фоновое изображение"
							class="main-slider__pattern"
						/>
						<img
							:src="slide.image"
							:alt="slide.title"
							class="main-slider__image"
						/>
					</div>
				</div>
			</swiperSlide>
			<div
				class="swiper-pagination swiper-pagination--main"
				slot="pagination"
			></div>
		</swiper>
		<div class="main-slider__buttons main-slider__container container">
			<button
				class="swiper-button-prev swiper-button-prev--main"
				slot="button-prev"
			>
				<svg
					width="12"
					height="22"
					viewBox="0 0 12 22"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M11.2593 21L1.25928 11L11.2593 1"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
			</button>
			<button
				class="swiper-button-next swiper-button-next--main"
				slot="button-next"
			>
				<svg
					width="12"
					height="22"
					viewBox="0 0 12 22"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M0.740725 1L10.7407 11L0.740723 21"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
			</button>
		</div>
	</div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
	components: {
		swiper,
		swiperSlide,
	},

	data() {
		return {
			swiperOption: {
				autoplay: true,
				loop: true,
				slidesPerView: 1,
				pagination: {
					el: '.swiper-pagination--main',
				},
				navigation: {
					nextEl: '.swiper-button-next--main',
					prevEl: '.swiper-button-prev--main',
				},
				allowTouchMove: true,
			},
		}
	},

	computed: {
		swiper() {
			if (this.slides) {
				this.swiper.slideTo(1, 0, false)
				return this.$refs.mySwiperMain.$swiper
			} else {
				return null
			}
		},

		slides() {
			const meta = this.$route.meta
			const slides = this.$store.getters.SLIDER
			if (meta.holder) {
				return slides.holder
			} else if (meta.partner) {
				return slides.partner
			} else {
				return null
			}
		},
	},
	mounted() {
		const meta = this.$route.meta

		if (meta.holder) {
			this.$store.dispatch('GET_SLIDER', { category: 1 })
		}

		if (meta.partner) {
			this.$store.dispatch('GET_SLIDER', { category: 2 })
		}
	},
}
</script>
